.pricing-table-mobile {
    width: 100%;
    max-width: 600px;
    margin: auto;
    background-color: #DEE8FF;
}

/* Button Container */
/* Button Container */
.pricing-buttons {
    display: flex;
    justify-content: center;
    background-color: #0A0F33;
    padding: 10px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 96%;
}

/* Buttons */
.pricing-button {
    background: none;
    border: none;
    color: white;
    font-weight: bold;
    padding: 12px 20px; /* Ensure consistent padding */
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    flex: 1; /* Ensure equal width for all buttons */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Selected Button */
.pricing-button.selected {
    background-color: #6A5ACD; /* Soft Purple */
    border-radius: 8px; /* Slightly rounded */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    position: relative; /* Keeps the button from moving */
    z-index: 2; /* Ensures it's above others */
}

/* Smooth Hover Effect */
.pricing-button:hover {
    background-color: #7B68EE; /* Lighter Purple */
}

/* Table Styling */
.pricing-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0px;
}

td {
    padding: 10px;
    border: 1px solid #ddd;
}

/* Feature Column */
.feature-name {
    background-color: #DEE8FF;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Helvetica-Neue", sans-serif;
    font-size: 11pt;
}

/* Animated Feature Selection */
.feature-cell.selected {
    background-color: #0A0F33;
    color: white;
    font-weight: bold;
    text-align: center;
    width: 20%;
    
}

/* Apply border-radius only to the last row's selected feature cell */
tr:last-child td.feature-cell.selected {
    border-radius: 0px 0px 15px 15px;
}


/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
