.pricing-table {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr; /* First column wider */
    gap: 0;
    padding: 0 50px;
    background-color: #DEE8FF;
    max-width: 1000px !important;
    margin: auto;
    border-radius: 10px;
}

.empty-header {
    background-color: transparent; /* Removes blue background */
}

.pricing-header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    background-color: #0f1931;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 170px;
}

.signup-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: #0f1931;
    width: 170px;
}

.signup-button {
    background-color: #EBEBEB;
    color: #0F1931;
    border: 1px solid #EBEBEB;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.signup-button:hover {
    background-color: black;
    color: white;
}

.feature-item {
    font-weight: bold;
    text-align: left;
    padding-left: 20px;
    margin-right: auto;
}

.feature-item, .feature-check {
    padding: 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature-check {
    background-color: #0f1931;
    font-size: 18px;
    text-align: center;
    color: white;
    width: 170px;
}

/* Apply border-radius to last feature check row */
.last-row {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 30px;
}

/* Responsive */
@media (max-width: 1024px) {
    .pricing-table {
        grid-template-columns: 1fr;
    }

    .feature-item {
        text-align: center;
    }
}
