.position-relative {
  position: relative;
}

.subscribe-button-home {
  background-color: #788FCB !important; /* Default color */
  height: 55px;
  color: white !important;
  border: 0;
  max-width: 200px;
  width: 100%;
  border-radius: 20px !important;
  padding: 8px 15px;
  font-weight: bold;
  font-size: 16px;
  box-shadow: none;
  border-color: transparent !important;
  font-family: "Helvetica-Neue", sans-serif !important;
  transition: background-color 0.3s ease-in-out;
}

.subscribe-button-home:hover {
  background-color: #5A6FAE !important; /* Darker blue on hover */
}

.home-quote {
  font-size: 40px !important;
  font-family: "Helvetica-Neue-Light", sans-serif !important;
  font-style: italic !important;
  color: #272F53;
  font-weight: bold;
  width:100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.home-quote-paragraph{
  font-family: "Helvetica-Neue-Light", sans-serif !important;
  font-size: 30px !important;
  color: #272F53 !important;
}

.gif-caption {
  color: white;
  font-family: "Helvetica-Neue", sans-serif;
  font-size: 62px;
  line-height: 75px;
  font-weight: bold;
}

.gif-p {
  color: white;
  font-family: "Helvetica-Neue", sans-serif;
  font-size: 42px;
  line-height: 45px;
}

.redefined-button-white {
  border-radius: 35px 35px 35px 35px !important;
  background-color: white !important;
  color: #272f53 !important;
  font-family: "Helvetica-Neue", sans-serif !important;
  font-size: 19px !important;
  line-height: 24px !important;
  border-color: white !important;
}

.redefined-button-white:hover {
  background-color: #272f53 !important;
  color: white !important;
}

.redefined-button-purple {
  border-radius: 25px 25px 25px 25px !important;
  background: #434a79 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  font-family: "Helvetica-Neue", sans-serif !important;
  font-size: 19px !important;
  line-height: 24px !important;
  border: 2px solid #ffffff !important;
}

.redefined-button-purple:hover {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  color: #434a79 !important;
  border: 2px solid #434a79 !important;
}
.platform-section {
  text-align: center;
  width: 100%;
}

.platform-title {
  font-size: 60px; /* Adjusted to match the second image */
  font-weight: bold;
  color:#0F1931;
  text-align: center;
  margin-bottom: 10px;
  font-family: "Stix-Two", serif;
}

.platform-text {
  color: #272F53;
  width: 100%;
  max-width: 900px !important;
  margin-left: auto;
  margin-right: auto;
  font-size: 23px;
  line-height: 40px;
  text-align: center;
  font-size: 30px;
  font-family: "Helvetica-Neu-Light", sans-serif !important;
  opacity: 0.7;
}

.register-container {
  display: flex;
  justify-content: center;
  margin-top: 50px !important;
}

.register-button {
  background-color: #EBEBEB;
  width:100%;
  max-width: 200px;
  color: #0F1931;
  border: 1px solid #EBEBEB;
  padding: 12px 25px;
  font-size: 30px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.register-button:hover {
  background-color: black;
  color: white;
}

/* @media (max-width: 768px) {
  .platform-title {
    font-size: 24px;
  }

  .platform-text {
    font-size: 14px;
    line-height: 20px;
  }
} */

/* Row 2 */
#imageRow2 {
  max-width: 100%;
}

.margin-t-100 {
  margin-top: 100px !important;
}

.margin-t-40 {
  margin-top: 40px !important;
}

.padding-t-40 {
  padding-top: 40px !important;
}

.padding-b-40 {
  padding-bottom: 40px !important;
}

.margin-b-40 {
  margin-top: 40px !important;
}

.margin-b-100 {
  margin-bottom: 100px !important;
}

.margin-t-25 {
  margin-top: 25px !important;
}

.subheading {
  color: #536bc1;
  font-size: 25px;
}

.heading {
  font-size: 46px;
}

.body-text {
  color: #222222;
  line-height: 32px;
  font-size: 18px;
}

.white-bg-black-col {
  background-color: #ffffff !important;
  color: #242a49 !important;
}

.white-bg-black-col:hover {
  background-color: #242a49 !important; /* Change background color on hover */
  color: #ffffff !important; /* Change text color on hover */
}

.white-bg-blue-col-2 {
  background-color: #ffffff !important;
  color: #333333 !important;
}

.white-bg-blue-col-2:hover {
  background-color: #536bc1 !important; /* Change background color on hover */
  color: #ffffff !important; /* Change text color on hover */
}

.blue-bg-white-col {
  background-color: #536bc1 !important;
  color: white !important;
}

.blue-bg-white-col:hover {
  background-color: white !important; /* Change background color on hover */
  color: #536bc1 !important; /* Change text color on hover */
}

/* Row 3 */
.h-center {
  text-align: center;
}

.row3-subheading {
  max-width: 25% !important;
}

.mw-80 {
  max-width: 80%;
}

.margin-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.padding-t-100 {
  padding-top: 100px;
}

.padding-b-100 {
  padding-bottom: 100px;
}

.custom-list-item::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #3181ed; /* Set the bullet point color to blue */
  border-radius: 50%; /* Make the bullet point circular */
  text-align: center;
  color: white; /* Text color for the checkmark */
  font-size: 16px; /* Adjust the font size as needed */
  margin-right: 10px; /* Adjust spacing between the bullet point and text */
  position: absolute;
  left: -30px; /* Adjust the horizontal position of the bullet point */
  top: 50%; /* Center the bullet point vertically */
  transform: translateY(-50%); /* Center the bullet point vertically */
}

.custom-list-item {
  list-style: none; /* Remove default bullet points */
  padding: 5px 0;
  position: relative;
}

.white-bg-custom-bullet {
  background-color: #fafafa !important;
  border: none !important;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.blue-bg-custom-bullet {
  background-color: #38487f !important;
  border: none !important;
  color: white !important;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.list-group {
  margin-bottom: 30px !important;
}

.container-fluid {
  overflow: hidden;
}

.footer-first-div {
  background-color: #fafafa !important;
}

.home-caption {
  font-size: 65px;
  color: #272f53 !important;
  font-weight: bold;
  line-height: 50px;
}

.home-subcaption {
  font-size: 32px !important;
  font-family: "Helvetica-Neue", sans-serif !important;
  color: #272f53 !important;
  line-height: 30px;
}

.white-color {
  color: white !important;
}

.initial-logo-container {
  min-height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.initial-logo {
  width: 200px; /* Adjust as needed */
}

body.scrolled .initial-logo-container {
  opacity: 0;
  pointer-events: none;
}

#fade-out-logo {
  opacity: 1;
  transition: 4s;
}

#fade-out-logo.fade-out {
  opacity: 0;
}

#fade-in-row {
  opacity: 0.01;
  transition: 2s;
}

#fade-in-row.fade-in {
  opacity: 1;
}

#buyers-quote {
  color: white !important;
  font-size: 30px;
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
}

@media (max-width: 768px) {
  .platform-title{
    font-size: 23pt;
  }

  .platform-text p {
    font-size: 16px;
    line-height: 1;
  }
  
  .register-button {
    background-color: #EBEBEB;
    width:100%;
    max-width: 150px !important;
    color: #0F1931;
    border: 1px solid #EBEBEB;
    padding: 2px 2px;
    font-size: 14px !important;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    height:36px;
    font-family: "Helvetica-Neue-Light", sans-serif !important;
  }

  .register-container{
    margin-top: 0px !important;
  }

  .home-quote{
    font-size: 20px !important;
    width: 85%;
  }

  .home-quote-paragraph{
    font-size: 16px !important;
  }

  .platform-title{
    font-size: 30px !important;
  }

  #join-home-page{
    font-size: 25px !important;
  }

  #buyers-quote {
    color: white !important;
    font-size: 18px !important;
    max-width: 1000px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
