/* CSS for Log-in and Circular Button */
#log-in-header a {
  font-family: "Lato", sans-serif;
  text-decoration: none;
  color: white;
}

/* Logo */
.logo img {
  max-height: 120px; /* Adjust the maximum height for responsiveness */
  width: auto;
}

.logo {
  max-height: 104px;
}

.circular-button {
  font-family: "Lato", sans-serif !important;
  border-radius: 50px 50px 50px 50px !important; /* Circular edges on the left and right sides */
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: 4rem;
  align-items: center;
  letter-spacing: 1px;
  padding: 1rem 2rem !important; /* Adjust padding for vertical and horizontal centering */
}

.responsive-link-container {
  display: flex;
  justify-content: space-between;
}

.join-now-btn {
  color: black !important; /* Customize text color */
  background-color: white !important; /* Customize background color */
  border-radius: 50px 50px 50px 50px !important; /* Circular edges on the left and right sides */
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: 4rem;
  display: flex;
  align-items: center;
}

.join-now-btn:hover {
  background-color: coral !important;
}

.responsive-link-container Nav.Link {
  margin-right: 10px; /* Adjust the margin as needed */
}

.ai-center {
  align-items: center;
}

#join-link {
  color: black !important;
}

.mh-104 {
  height: 104px;
}

/* Overlay Container */
.overlay-container {
  position: relative;
  width: 100%; /* Adjust width for responsiveness */
  padding-left: 15% !important;
  padding-right: 15% !important;
  margin: 0 auto; /* Center the overlay horizontally */
  max-height: 104px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000aa !important;
  z-index: 2;
  cursor: pointer;
}

/* Top Menu */
.top-menu {
  display: flex;
  flex-wrap: wrap; /* Wrap menu items when screen size is small */
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: transparent;
  color: white;
}

.navbar-options {
  margin-left: 50px;
}

.navbar-options a {
  margin-right: 25px;
  font-size: 18px;
  font-weight: 400;
}

#login-option {
  margin-right: 20px;
}

/* Menu Options */
.menu-options {
  margin-left: 0; /* Remove left margin on smaller screens */
  margin-right: auto;
  list-style: none;
  display: flex;
  padding: 0;
  flex-wrap: wrap; /* Wrap menu items when screen size is small */
  justify-content: center; /* Center-align menu items */
}

.menu-options li {
  margin-right: 15px; /* Decrease margin for smaller screens */
  font-family: "Lato", sans-serif;
  color: white;
}

.menu-options a {
  text-decoration: none;
  color: white;
}

/* Buttons */
.buttons button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 50px; /* Apply circular edges to the button */
}

.arrow-character {
  font-size: 13px;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.nav-link {
  color: white !important;
  transition: color 0.3s; /* Smooth transition for color change */
}

.nav-link:hover {
  color: #536bc1 !important;
}

#join-btn {
}

#responsive-navbar-nav {
  max-height: 75px;
}

@media (max-width: 768px) {
  .h-auto-mobile {
    height: auto;
  }
}
