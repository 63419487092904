.contact-us-row {
  background: #3b477e 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #000000d6;
  border-radius: 40px 40px 0px 0px;
  margin-top: 2%;
}

#contact-us-heading {
  font-size: 50px;
  line-height: 90px;
  font-family: "Helvetica-Neue", sans-serif;
  color: #ffffff;
}

#contact-us-paragraph {
  font-size: 20px;
  line-height: 30px;
  font-family: "Helvetica-Neue-Light", sans-serif;
  color: #ffffff;
  text-align: center;
}

.contact-us-col {
  padding-left: 10%;
  padding-right: 10%;
}

.MuiInput-underline-white:before {
  border-bottom-color: white !important; /* Sets the color of the animation line to white */
}

.MuiInput-contact-us {
  text-align: center; /* Center align the text horizontally */
  display: flex !important;
  align-items: center !important; /* Center align the text vertically */
  justify-content: center; /* Center align the text horizontally */
  background-color: #3b477e !important;
  font-family: "Helvetica-Neue-Light", sans-serif;
  font-size: 18px;
  line-height: 30px;
}

.textarea-contact-us {
  font-family: "Helvetica-Neue-Light", sans-serif;
  color: #3b477e;
  font-size: 18px;
}

@media (max-width: 576px) {
  #contact-us-heading {
    line-height: 1.5;
    font-size: 1.7rem; /* Smaller font size for mobile */
    padding-right: 2rem;
    padding-left: 2rem;
  }

  #contact-us-paragraph {
    line-height: 1.5;
    font-size: 0.95rem; /* Smaller font size for mobile */
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .textarea-contact-us{
    height:350px;
  }
}
