#sellers-quote {
  color: white !important;
  font-size: 30px;
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .home-caption {
    font-size: 50px;
    /* Smaller font size on mobile */
    line-height: 45px;
  }

  .home-subcaption {
    font-size: 25px;
    text-align: left;
    /* Align left on mobile */
    margin-top: 10px;
    /* Add some spacing */
  }

  .home-paragraph {
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    /* Full width on mobile */
    text-align: left;
    /* Align left on mobile */
    margin-top: 15px;
    /* Add margin on top for better spacing */
  }

  /* Mobile Styles for left alignment */
  @media (max-width: 768px) {
    h2.left-align-mobile {
      line-height: 0.6 !important;
      font-size: 45px;
    }

    p.home-subcaption {
      font-size: 25px !important;
      padding-left: 3%;
    }

    p.home-paragraph {
      line-height: 1.2 !important;
      margin-bottom: 50px;
    }

    .left-align-mobile {
      text-align: left !important;
      /* Enforce left alignment on mobile */
      margin-left: 0 !important;
      /* Remove auto-centering margin */
      margin-right: 0 !important;
      /* Remove auto-centering margin */
    }

    #sellers-quote {
      color: white !important;
      font-size: 18px !important;
      max-width: 1000px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 50px;
      padding-left: 50px;
      padding-right: 50px;
    }

    #mobile-section-sellers {
      margin-right: auto;
      padding-right: 10%;
      padding-left: 10%;
      padding-top: 10%;
    }
}
}