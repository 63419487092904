.statistic-card-badge {
  background-color: #536bc1 !important;
}

.statistic-card-text {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  line-height: 25px;
  margin-bottom: 5% !important;
}

.statistic-card-statistic {
  font-family: "Lato", sans-serif;
}

.statistic-card {
  width: 250px !important; /* Fixed width for desktop */
  height: 200px !important; /* Fixed height for desktop */
}

@media (max-width: 768px) {
  .statistic-card {
    width: 100% !important; /* Full width on mobile */
    height: auto !important; /* Auto height on mobile */
  }

  .statistic-card-badge {
    height: 30px !important; /* Smaller badge for mobile */
    width: 30px !important;
  }

  .statistic-card-text {
    font-size: 10px !important; /* Smaller text size for mobile */
  }

  .statistic-card-statistic {
    font-size: 20px !important; /* Adjust statistic size for mobile */
  }
}
