.stats-banner {
  background-color: #0f1931; /* Dark background */
  color: white;
  padding: 30px 10px;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  max-width: 1200px; /* Keeps size fixed */
  position: relative; /* Change to relative */
  margin-top: -50px; /* Adjust this based on the layout */
  z-index: 10;
}

  
  .stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .stat-value {
    font-size: 50px;
    line-height: 90px;
    font-weight: 600;
    font-family: "Stix-Two", serif;
    font-style: normal;
    margin-bottom: 5px;
    line-height: normal;
  }
  
  .stat-label {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
    font-family: "Helvetica-Neue-light", sans-serif;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .stats-banner {
      width: 80%;
    }
  
    .stat-value {
      font-size: 28px;
    }
  
    .stat-label {
      font-size: 14px;
    }

    .stats-banner{
      padding: 10px 5px;
      padding-bottom: 0;
      margin-bottom: 50px;
    }
  }
  