.heading-marketplace{
    font-family: 'Geologica', sans-serif;
    color: #000000;
    font-size: 24px;
}

.filter-marketplace{
    width:104px;
    height: 42px;
    background-color: white;
}

.active-link{
    background-color: #536BC1 !important;
}

.mui-price-slider{
    font-size: 14px !important;
    font-family: 'Lato', sans-serif !important;
    padding-left : 10% !important;
    margin-top: 3% !important;
    letter-spacing: 0.03rem !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.text-align-center input{
    text-align: center !important;
}

.button-blue-theme{
    background-color: #536BC1 !important;
}

.button-blue-theme:hover{
    background-color: #242A49 !important;
}

.listing-col{
    width: 33.33% !important;
}

.pt-15{
    padding-top: 15px !important;
}

.mt-45{
    margin-top: 45px !important;
}

@media (max-width: 768px) {
    /* Adjust filter elements to stack vertically */
    .filter-marketplace {
        width: 100% !important; /* Full width for mobile */
        margin-bottom: 10px; /* Add space between filters */
    }

    /* Flex container for categories */
    .filter-marketplace .dropdown-menu div {
        display: block; /* Stack buttons vertically */
        padding-left: 0; /* Remove extra padding */
        margin-right: 0; /* Remove horizontal margin */
    }

    /* Category buttons for mobile */
    .filter-marketplace button {
        width: 100%; /* Full width buttons */
        margin-right: 0; /* Remove right margin */
        margin-bottom: 8px; /* Add spacing between buttons */
    }

    /* Price slider adjustments */
    .mui-price-slider {
        font-size: 12px !important; /* Smaller font for mobile */
        padding-left: 0 !important;
    }

    /* Adjust slider width for mobile */
    .filter-marketplace .MuiSlider-root {
        width: 90% !important;
        margin-left: 5%; /* Center the slider */
    }

    /* Listings take full width on mobile */
    .listing-col {
        width: 100% !important;
    }

    .pl-15-mobile{
        padding-left: 15% !important;
    }

    .pagination {
        font-size: 12px; /* Smaller font for mobile */
        justify-content: center; /* Ensures pagination stays centered */
        width: 100%; /* Makes pagination take full width on mobile */
      }
      
      .pt-0-mobile{
        padding-top: 0 !important;
      }
      
      .mt-17-mobile{
        margin-top: 17px !important;
      }
}