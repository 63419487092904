/* Scoped styles for only this FAQ accordion */
.enterprise-accordion {
  max-width: 1200px;
  margin: auto;
  font-family: "Helvetica-Neue-Light", sans-serif;
}

/* Accordion Items */
.enterprise-accordion .custom-accordion-item {
  background-color: #0d1b2a !important;
  border: none;
  border-radius: 30px !important;
  overflow: hidden;
  margin-bottom: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  padding: 15px;
}

/* Styling the Accordion Header */
.enterprise-accordion .accordion-header {
  border-radius: 15px !important;
}

/* Accordion Button (Header Styling) */
.enterprise-accordion .accordion-button {
  background-color: #0d1b2a !important;
  color: white !important;
  border: none;
  padding: 18px 24px;
  font-size: 1.1rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
  border-radius: 15px !important;
}

/* Remove border effect when clicking */
.enterprise-accordion .accordion-button:focus {
  box-shadow: none !important;
}

/* Hover Effect */
.enterprise-accordion .accordion-button:hover {
  background-color: #0d1b2a !important;
}

/* When an item is expanded */
.enterprise-accordion .accordion-button:not(.collapsed) {
  background-color: #0d1b2a !important;
  color: white !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* Body Content */
.enterprise-accordion .custom-accordion-body {
  background-color: #0d1b2a !important;
  color: white !important;
  font-size: 1rem;
  padding: 18px 24px;
  line-height: 1.5;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  opacity: 0.8;
  text-align: left;
}

/* Remove the border-top line inside expanded content */
.enterprise-accordion .custom-accordion-body::before {
  content: none !important;
}

/* Remove default caret (arrow) */
.enterprise-accordion .accordion-button::after {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
  color: #788FCB !important;
  filter: invert(60%) sepia(20%) saturate(400%) hue-rotate(200deg) brightness(90%) contrast(90%);
}

/* Rotate caret when expanded */
.enterprise-accordion .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .enterprise-accordion {
    max-width: 100%;
    padding: 0 10px;
  }
  .enterprise-accordion .custom-accordion-item {
    border-radius: 15px !important;
    padding: 10px;
  }
  .enterprise-accordion .accordion-button {
    padding: 14px 18px;
    font-size: 1rem;
  }
  .enterprise-accordion .custom-accordion-body {
    font-size: 0.9rem;
    padding: 14px 18px;
  }
}
