.accordion-button {
    border: none; /* Remove the border from the button */
    border-bottom: 1px solid #ccc; /* Add a line below each button */
  }
  
  .accordion-item {
    border: none; /* Remove the border around the entire item */
  }
  
  .accordion-body {
    border-top: none; /* Remove the border from the top of the body */
  }

  .mobile-accordion > .accordion-header > .accordion-button{
    background-color: white !important;
    font-family: "Helvetica-Neue", sans-serif;
    color: #272F53 !important;
  }

  .mobile-accordion{
    border-left: none !important;
    border-right: none !important;
  }

  .accordion-button:focus{
    border-color: none !important;
  }