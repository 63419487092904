.listing-card {
  font-family: "Lato", sans-serif;
}

.listing-card-text {
  font-size: 14px !important;
  font-family: "Lato", sans-serif !important;
}

.listing-card-tag-label {
  opacity: 0.7;
  font-weight: bold;
  font-size: 10px;
  line-height: 25px;
  height: 21px;
}

.listing-card-tag{
    font-weight: bolder;
    font-size: 14px;
    line-height: 25px;
}

.custom-link{
  text-decoration: none;
  color: inherit;
}

.custom-link:hover{
  color: #49517c;
}