@media (max-width: 768px) {
    h3.gif-caption {
      font-size: 2.5rem; /* Adjust as needed */
      font-weight: bolder;
      line-height: 1;
    }
  }

  @media (max-width: 768px) {
    h4.gif-p {
      line-height: 1.2; /* Decreased line height for mobile */
      font-size: 1.5rem;
    }
  }

  @media (max-width: 768px) {
    h2.home-caption {
      font-size: 1.78rem; /* Smaller font size on mobile */
      margin-top: 10px;  /* Reduced margin */
      line-height: 1.2;  /* More compact line height */
      text-align: center;
    }

    p.home-subcaption {
        font-size: 1.2rem !important; /* Smaller font size on mobile */
        /* margin: 0.5% 0;    Reduce top/bottom margin */
        padding-left: 5%; /* Reduce padding for mobile */
        line-height: 1.3; 
        text-align: center;
      }

    .w-100-mobile{
        width: 100% !important;
    }
  }