/* .ff-lato{
    font-family: 'Lato', sans-serif !important;
}

.onboarding-button {
    font-size: 16px;
    line-height: 25px;
    font-family: "Lato", sans-serif;
  }

  .error-message {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  .success-message {
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .id-upload-react-input{
    border: 0 !important
  }

  .id-upload-react-input:focus{
    box-shadow: none !important;
  }

  .onboarding-text{
    font-family: "Helvetica-Neue", sans-serif;
    font-size: 22px;
    color: #272F53;
  } */

.onboarding-caption {
  font-size: 40px;
  line-height: "50px";
}

.onboarding-paragraph {
  font-size: "20px";
}

.onboarding-caption-paragraph {
  font-size: "30px";
  margin-bottom: 24px;
}

.onboarding-note{
  font-size: 16px;
}

.congratulations-tick{
  max-width: 10% !important;
}

@media (max-width: 768px) {
  .onboarding-caption {
    font-size: 22px;
    /* line-height: "30px"; */
  }

  .onboarding-text {
    font-size: 13px;
  }

  .onboarding-paragraph {
    font-size: 18px;
  }

  .onboarding-caption-paragraph {
    font-size: "24px";
    margin-bottom: 12px;
  }

  .onboarding-note{
    font-size: 13px;
  }

  .congratulations-tick{
    max-width: 25% !important;
  }
}
