.mobile-header-menu {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Relative for the absolute dropdown */
  overflow: hidden; /* Prevent overflow issues */
}

.mobile-header-logo {
  max-width: 170px;
  max-height: 80px;
  flex-shrink: 0; /* Ensure logo does not shrink or cause layout issues */
}

.nav.position-relative {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0; /* Ensure the nav doesn't shrink */
  overflow: hidden; /* Prevent overflow from dropdowns */
}

#mobile-nav-dropdown + .dropdown-menu {
  position: absolute !important;
  top: 50px; /* Adjust positioning */
  right: 0;
  z-index: 999;
  max-width: 90vw; /* Ensure dropdown doesn't overflow the viewport */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

#mobile-nav-dropdown + .dropdown-menu .dropdown-item {
  position: relative;
  z-index: 3;
  color: #000;
  background-color: #ffffff;
  white-space: nowrap; /* Prevent text from wrapping and breaking layout */
}

.fixed-dropdown .dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1000;
  min-width: 200px;
  max-width: 90vw; /* Ensure dropdown doesn't overflow the screen */
  transform: translateX(0%);
  background-color: white;
  border-radius: 5px;
  overflow: hidden; /* Prevent content overflow */
}

.mobile-menu {
  background-color: transparent !important;
  overflow-x: hidden; /* Ensure no horizontal scrolling in the mobile menu */
}

.mobile-nav-items {
  margin-left: 15px;
}

.mobile-header-option {
  font-size: 18px !important;
  text-align: center;
  margin: 0 8px;
  color: #ffffff;
  white-space: nowrap; /* Prevent text from wrapping and breaking layout */
}

@media (max-width: 768px) {
  .mobile-header-logo {
    max-width: 180px;
    max-height: 90px;
  }
}

/* Ensure no horizontal overflow on smaller screens */
.mobile-header-menu.navbar.navbar-expand-lg.navbar-dark {
  overflow: visible !important;
}

/* Make overflow visible for the container-fluid with d-flex justify-content-between align-items-center */
.d-flex.justify-content-between.align-items-center.container-fluid {
  overflow: visible !important;
}
