.plan-card-container-new {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the cards */
  padding-left: 10% !important;
  padding-right: 10% !important;
}

/* Ensure proper alignment on desktop */
@media (min-width: 1200px) {
  .custom-width-desktop {
    max-width: 350px !important;
    width: 100% !important;
  }
}

/* Stack cards vertically and center on mobile */
@media (max-width: 768px) {
  .plan-card-container-new {
    flex-direction: column;
    display: block;
    padding-left: 5% !important;
    padding-right: 5% !important;
    align-items: center; /* Center-align the cards */
  }

  .plan-card-container-new > .col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-bottom: 20px !important;
  }
}
