.scrolling-sections-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.section {
  display: flex;
  flex-direction: column;
}

.section-paragraph {
  transition: opacity 0.8s ease;
  font-family: "Helvetica-Neue-Light", sans-serif;
  color: #272f53;
  font-size: 22px;
  opacity: 0;
}

.section-paragraph.section-paragraph-active {
  opacity: 1;
  padding-bottom: 2%;
}

.section h2 {
  font-size: 20px;
  transition: font-size 0.8s ease;
}

.section.active h2 {
  font-size: 30px;
}

.section-heading.section-heading-active {
  font-family: "Helvetica-Neue", sans-serif;
  color: #272f53;
  text-transform: uppercase;
  font-size: 40px !important;
  font-weight: bold;
  text-align: left;
}

.section-heading {
  padding-top: 2%;
  padding-bottom: 1%;
  transition: font-size 0.8s ease;
  font-family: "Helvetica-Neue", sans-serif;
  color: #272f53;
  text-transform: uppercase;
  font-size: 27px !important;
  font-weight: bold;
  text-align: left;
}

@media (max-width: 768px) {
  .section-heading {
    font-size: 1rem !important; /* Smaller font size on mobile */
    margin-top: 10px; /* Reduced margin */
    line-height: 1.2; /* More compact line height */
    text-align: left;
  }

  .section-heading.section-heading.section-heading-active {
    font-size: 1.5rem !important; /* Smaller font size on mobile */
    margin-top: 10px; /* Reduced margin */
    line-height: 1.2; /* More compact line height */
    text-align: left;
  }

  p.home-subcaption {
    font-size: 1.2rem !important; /* Smaller font size on mobile */
    /* margin: 0.5% 0;    Reduce top/bottom margin */
    padding-left: 5%; /* Reduce padding for mobile */
    line-height: 1.3;
    text-align: center;
  }

  .section-scrolling-category {
    text-align: left;
  }

  .w-100-mobile {
    width: 100% !important;
  }

  .p-0-mobile {
    padding: 0 !important;
  }

  .section-paragraph.section-paragraph-active{
    line-height: 1.2 !important;
  }

  .section-scrolling-category-separator {
    border-right: 0px !important;
  }
  .ml-8-mobile {
    margin-left: 8% !important;
  }
}
