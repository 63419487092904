/* Define the 'Inter' font */
@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter-Regular.ttf") format("truetype");
}

/* Define the 'Poppins' font */
@font-face {
  font-family: "Poppins";
  src: url("../../public/fonts/Poppins-Medium.ttf") format("truetype");
}

/* Define the 'Lato' font */
@font-face {
  font-family: "Lato";
  src: url("../../public/fonts/Lato-Regular.ttf") format("truetype");
}

/* Define the 'Stix-Two' font */
@font-face {
  font-family: "Stix-Two";
  src: url("../../public/fonts/STIXTwoText-Regular.ttf") format("truetype");
}

/* Define the 'Satoshi' font */
@font-face {
  font-family: "Satoshi";
  src: url("../../public/fonts/Satoshi-Medium.ttf") format("truetype");
}

/* Define the 'Geologica' font */
@font-face {
  font-family: "Geologica";
  src: url("../../public/fonts/Geologica-Medium.ttf") format("truetype");
}

/* Define the 'Helvetica-Neue' font */
@font-face {
  font-family: "Helvetica-Neue";
  src: url("../../public/fonts/Helvetica-Neue-Medium.ttf") format("truetype");
}

/* Define the 'Helvetica-Neue' font */
@font-face {
  font-family: "Helvetica-Neue-Light";
  src: url("../../public/fonts/Helvetica-Neue-Light.ttf") format("truetype");
}
