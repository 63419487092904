.favorite-button-marketplace-asset {
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: 20px !important;
}

.favorite-button-marketplace-asset.disabled {
  opacity: 0.1;
}

.filled-heart-marketplace-asset {
  color: rgb(255, 67, 100) !important;
}

.unfilled-heart-marketplace-asset {
  color: #222222 !important;
  opacity: 0.5;
}

.highlight-line {
  height: 50%;
  border-left: 1px solid black;
  display: math;
  margin-left: 10px;
  margin-right: 10px;
  opacity: 0.7;
}

.highlight-entry {
  align-self: center;
  color: #222222;
  opacity: 0.7;
  font-family: "Lato", sans-serif;
}

.marketplace-asset-title {
  font-family: "Geologica", sans-serif;
  font-size: 42px !important;
}

.marketplace-asset-location-container {
  display: -webkit-inline-box;
  align-items: center;
}

.marketplace-asset-location-tag {
  margin-right: 10px;
  font-family: "Geologica", sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: 25px;
}

.marketplace-asset-location {
  font-family: "Lato", sans-serif;
  opacity: 0.5;
  color: black;
  line-height: 25px;
  font-size: 16px;
}

.marketplace-asset-additional {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
}

.marketplace-asset-additional-list {
  padding-left: 30px !important;
  list-style-position: outside;
}

.marketplace-asset-additional-list-item {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #222222;
  opacity: 0.7;
}

.marketplace-asset-button {
  font-size: 16px;
  line-height: 25px;
  font-family: "Lato", sans-serif;
}

.image-button {
  height: 90px;
  width: 101px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.marketplace-asset-tab-title {
  font-family: "Geologica", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  color: #000000;
}

.marketplace-asset-tab-subtitle {
  font-size: 13px;
  line-height: 25px;
  opacity: 0.5;
  margin-bottom: 5px;
}

.marketplace-asset-tab-text {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 25px;
}

.mr-20{
  margin-right: 20px;
}

.mt-35{
  margin-top: 35px !important;
}

@media (max-width: 768px) {
  .marketplace-asset-title {
    font-family: "Geologica", sans-serif;
    font-size: 23px !important;
  }

  .mr-10px-mobile{
    margin-right:10px !important;
  }

  .marketplace-asset-location-tag{
    font-size: 23px !important;
  }

  .mt-10px-mobile{
    margin-top: 10px !important;
  }
}
