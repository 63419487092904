/* General Container */
.newsletter-container {
    background-color: white;
    padding: 50px 20px;
    margin: 30px auto;
    text-align: center;
    max-width: 1200px;
  }
  
  /* Heading */
  .newsletter-heading {
    font-size: 40px;
    font-weight: bold;
    font-family: "Helvetica-Neue", sans-serif;
    color: #0f1931;
    margin-bottom: 30px;
  }
  
  /* Input Section */
  .newsletter-input-container {
    display: flex;
    justify-content: center;
  }
  
  /* Input Wrapper */
  .newsletter-input-wrapper {
    display: flex;
    align-items: center;
    border-radius: 30px;
    height: 55px;
    max-width: 600px;
    width: 100%;
    background-color: #f2f2f2;
    box-shadow: none;
    padding: 5px 10px;
    padding-right: 0 ;
    gap: 10px;
  }
  
  /* SVG Email Icon */
  .newsletter-icon {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
  }
  
  /* Input Field */
  .newsletter-input {
    border: none;
    outline: none;
    background: transparent;
    flex: 1;
    font-size: 16px;
    color: #333;
    padding: 10px;
  }
  
  /* Subscribe Button */
  .subscribe-button-home {
    background-color: #6c7ee1;
    border-radius: 20px;
    padding: 12px 20px;
    border: none;
    font-size: 16px;
    color: white;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    white-space: nowrap;
  }
  
  .subscribe-button-home:hover {
    background-color: #5a6bcf;
  }
  
  /* Privacy Policy Text */
  .newsletter-privacy {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
    font-style: italic;
  }
  
  /* Privacy Policy Link */
  .privacy-link {
    color: #6c7ee1;
    text-decoration: none;
  }
  
  .privacy-link:hover {
    text-decoration: underline;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .newsletter-container {
      padding: 30px 15px;
    }
  
    .newsletter-heading {
      font-size: 20px;
    }
  
    .newsletter-input-wrapper {
      flex-direction: row;
      height: auto;
      padding: 8px;
      gap: 5px;
      border-radius: 10px;
    }
  
    .newsletter-icon {
      margin-right: 0;
      font-size: 20px;
    }
  
    .newsletter-input {
      font-size: 14px;
      padding: 8px;
    }
  
    .subscribe-button-home {
      padding: 10px 16px;
      font-size: 14px;
      border-radius: 10px;
    }
  }
  