.summary-card {
    width:100%;
    max-width: 1050px;
    border-radius: 70px; /* Rounded shape */
    border: none;
    padding: 25px; /* Adjusted padding */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center content */
}

.summary-card-title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px; /* Adds spacing between title and text */
}

.summary-card-text {
    font-size: 26px;
    font-family: 'Helvetica-Neue', sans-serif;
    line-height: 26px;
    text-align: center;
}

@media (max-width: 768px) {
    .summary-card {
        border-radius: 50px;
        width: 95%;
        padding: 10px;
        height: 85px;
    }

    .summary-card-title {
        font-size: 18px;
        margin-bottom:5px;
    }

    .summary-card-text {
        font-size: 12px;
        line-height: 1;
        margin-bottom: 2px;
        font-family: 'Helvetica-Neue', sans-serif;
        opacity: 0.8;
    }
}
