/* Scope styles to this component by targeting the unique class */
.header-marketplace-navbar .navbar-collapse {
  position: relative; /* Relative positioning to keep it within the document flow */
  transition: height 0.3s ease; /* Smooth transition for expansion */
}

/* Adjust the collapsing behavior for this specific navbar */
.header-marketplace-navbar .collapse.show {
  display: block; /* Ensure the menu pushes content down when shown */
  height: auto; /* Allow the menu to expand naturally */
  padding-bottom: 15px; /* Add some space when expanded */
}

/* Ensure the dropdown menu doesn't shift the layout */
.header-marketplace-navbar .fixed-dropdown .dropdown-menu {
  position: absolute !important; /* Position the dropdown over the content */
  top: 50px; /* Adjust the position to be below the profile icon */
  right: 0; /* Align it to the right of the screen */
  z-index: 1000; /* Ensure it's on top of other elements */
  min-width: 200px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Make sure dropdown items are visible */
.header-marketplace-navbar .fixed-dropdown .dropdown-item {
  color: #222 !important; /* Set the text color */
  white-space: nowrap; /* Prevent text from wrapping */
}

/* Control the transition for the dropdown */
.header-marketplace-navbar .fixed-dropdown.show .dropdown-menu {
  display: block !important; /* Ensure it shows when the dropdown is open */
  opacity: 1; /* Smooth visibility */
  transition: opacity 0.3s ease; /* Add a fade effect */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .header-marketplace-navbar .navbar-options-marketplace .nav-link {
    padding-left: 20px !important; /* Reduce padding on mobile */
    font-size: 14px; /* Adjust font size for mobile */
  }

  .header-marketplace-navbar .marketplace-header-logo {
    max-width: 150px;
    max-height: 70px;
  }

  .header-marketplace-navbar .navbar-collapse {
    height: auto; /* Allow the collapse to push content down */
    padding-bottom: 15px; /* Space below the expanded menu */
  }

  .header-marketplace-navbar .navbar-toggler {
    position: relative;
    z-index: 2;
  }

  /* Make the toggle button more discreet but visible */
  .header-marketplace-navbar .navbar-toggler {
    background-color: transparent !important; /* Transparent background */
    border: 1px solid #536bc1 !important; /* Subtle border in your desired color */
    outline: none !important; /* Remove default focus outline */
    box-shadow: none !important; /* Remove any box shadow on focus */
  }

  .header-marketplace-navbar .navbar-toggler:focus {
    outline: none !important; /* Ensure no outline appears when focused */
    box-shadow: none !important; /* Remove focus box shadow */
  }

  .header-marketplace-navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='%23536BC1'%3E%3Cpath stroke='rgba(83, 107, 193, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
    /* Use #536BC1 for the toggle icon */
  }
}
