.getstarted-highlight {
    /* Add general styling for the component container here */
  }
  
  .getstarted-enumeration {
    font-family: "Helvetica-Neue", sans-serif;
    font-size: 45px;
    letter-spacing: 0px;
    color: #526ABF;
  }
  
  .getstarted-heading {
    font-family: "Helvetica-Neue", sans-serif;
    font-size: 45px;
    font-weight: bold;
    line-height: 45px;
    margin-bottom: 30px;
  }
  
  .getstarted-paragraph {
    font-family: "Helvetica-Neue-light", sans-serif;
    font-size: 18px;
  }
  