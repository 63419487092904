#getstarted-main-title {
    font-family: "Stix-Two";
    font-weight: bold;
    font-size: 50px;
  }
  
  #getstarted-main-subtitle {
    font-style: italic;
    font-family: "Helvetica-Neue-Light", sans-serif;
    font-size: 20px;
    width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
  
  #aboutus-quote-container {
    background-color: #dce2f5;
    padding: 40px 40px;
    min-height: 350px; /* Ensures a fixed height */
    width: 100vw; /* Full width */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  #aboutus-quote-content {
    max-width: 900px; /* Limits width on large screens */
  }
  
  #aboutus-quote-title {
    font-family: "Stix-Two", serif;
    font-weight: bold;
    font-size: 55px;
  }
  
  #aboutus-quote-description {
    font-size: 20px;
    font-family: "Helvetica-Neue-Light", sans-serif;
  }
  
  #aboutus-quote-button {
    font-family: "Helvetica-Neue-Light", sans-serif;
    font-size: 18px;
    padding: 12px 24px;
    font-weight: bold;
    cursor: pointer;
  }

  #meetEpendisisTeam {
    font-family: "Helvetica-Neue", sans-serif;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    #meetEpendisisTeam {
      font-size: 30px;
      margin-bottom: 20px;
    }

    #getstarted-main-title {
        font-family: "Stix-Two";
        font-weight: bold;
        font-size: 35px;
        margin-top: 20px;
      }
      
      #getstarted-main-subtitle {
        font-style: italic;
        font-family: "Helvetica-Neue-Light", sans-serif;
        font-size: 15px;
        width: auto !important;
        margin-left: auto;
        margin-right: auto;
      }

      .mobile-extra-class-highlight{
        margin-bottom: 20px;
        min-width: 80% !important;
        max-width: 80% !important;
      }

      #aboutus-quote-title{
        font-size: 30px;
      }

      #aboutus-quote-description {
        font-size: 16px;
      }
      
      #aboutus-quote-button {
        padding: 2px;
      }
  }