/* General Section Styling */
.home-property-section {
    text-align: center;
    padding: 40px 5%;
    max-width: 1400px;
    margin: 0 auto; /* Keeps content centered on large screens */
  }
  
  /* Title */
  .platform-title {
    font-size: 48px;
    font-weight: bold;
    color: #0f1931;
    text-align: center;
    font-family: "Stix-Two", serif;
  }

  .property-card-title{
    font-family: "Helvetica-Neue-light", sans-serif;
    font-size: 35px !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
  }

  .property-card-paragraph{
    font-family: "Helvetica-Neue-light", sans-serif;
  }
  
  /* Services Button */
  .redefined-button-purple {
    display: inline-block;
    padding: 16px 40px;
    background-color: #434a79;
    color: white;
    font-size: 19px;
    font-weight: bold;
    border-radius: 25px;
    text-decoration: none;
    transition: all 0.3s ease;
    border: 2px solid #ffffff;
  }
  
  .redefined-button-purple:hover {
    background-color: white;
    color: #434a79;
    border: 2px solid #434a79;
  }
  
  /* Staggered Grid Layout */
  .property-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;
    gap: 20px;
    max-width: 1100px; /* Prevents stretching on large screens */
    margin-left: auto !important;
    margin-right: auto !important;
  }
  
  /* Property Card Styling */
  .property-card {
    width: 100% !important; /* Fixed width */
    max-width: 330px !important;
    height: 450px; /* Fixed height */
    padding: 35px !important;
    border-radius: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:0 !important;
  }
  
  /* Dark & Light Card Colors */
  .dark-card {
    background-color: #0f1931;
    color: #ffffff;
  }
  
  .light-card {
    background-color: #ffffff !important;
    color: #272F53 !important;
  }
  
  /* Typography */
  .property-card h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .property-card p {
    font-size: 16px;
    opacity: 0.8;
  }
  
  /* Arrow Styling */
  .arrow-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 230px !important;
    width: 100% !important; /* Fixed width */
    max-width: 330px !important;
    margin: 0;
    cursor: pointer; /* Makes it clickable */
    transition: transform 0.3s ease, color 0.3s ease; /* Smooth hover effect */
  }

  .arrow-container:hover {
    transform: scale(1.1); /* Slight zoom effect */
  }
  
  .arrow-icon {
    font-size: 40pt !important;
    color: #0f1931;
    transition: color 0.3s ease; /* Smooth color transition */
  }

  .arrow-container:hover .arrow-icon {
    color: #434a79; /* Change color on hover */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .home-property-section {
      max-width: 95%;
    }
  
    .property-cards {
      flex-direction: row;
      justify-content: space-around;
    }
  
    .property-card {
      width: 280px;
      height: 230px;
    }
  }
  
  @media (max-width: 768px) {
    .platform-title {
      font-size: 32px;
    }
  
    .property-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .property-card {
      width: 90%;
      height: auto;
    }
  
    .arrow-container {
      justify-content: center;
      height:30px !important;
      margin-right: auto;
      margin-left: auto;
      margin-top: 50px !important;
    }

    .read-more-text {
      display: none; /* Hidden by default */
      font-size: 18px;
      font-weight: 600;
      color: #0f1931;
      font-family: "Helvetica-Neue-light", sans-serif;
      display: inline-block; /* Makes it visible */
  }
  }
  