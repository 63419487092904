/* Footer Container */
.footer-mobile {
  background-color: #EBEBEB; /* Light gray */
  padding: 25px 15px;
  text-align: center;
  font-family: "Arial", sans-serif;
  max-width: 400px;
  margin: 0 auto;
}

/* Title */
.footer-title {
  font-size: 18px;
  font-weight: 600;
  color: #2c4e9c; /* Dark blue */
  margin-bottom: 12px;
}

/* Useful Links */
.footer-links {
  margin-bottom: 15px;
}

.footer-links h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin: 0;
}

.footer-links a {
  font-size: 14px;
  color: #2c4e9c;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Contact Section */
.footer-contact {
  margin-bottom: 15px;
}

.footer-contact h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.footer-email {
  font-style: italic;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
}

.footer-contact address {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  font-weight: 500;
}

/* Copyright */
.footer-copyright {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
}

/* Mobile Styling */
@media (max-width: 480px) {
  .footer-mobile {
    padding: 20px 10px;
  }

  .footer-title {
    font-size: 18px;
    width: 70% !important;
    margin-right: auto;
    margin-left: auto;
  }

  .footer-links h4,
  .footer-contact h4 {
    font-size: 15px;
    line-height: 0.8;
    font-family: "Helvetica-Neue", sans-serif !important;
  }

  .footer-links a,
  .footer-email,
  .footer-contact address {
    font-size: 13px;
  }

  .footer-contact{
    margin-top: 35px;
  }

  .footer-copyright {
    font-size: 11px;
  }
}
