/* Keep the navbar fixed in layout */
.header-navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw !important; /* Ensures it spans the full viewport width */
  z-index: 1050 !important; /* Keeps it above all other elements */
  background: rgba(0, 0, 0, 0.7); /* Optional: Add slight transparency */
}

.wrapper {
  position: relative;
  overflow: visible !important; /* Prevents clipping */
}

/* Container to keep content centered and fixed */
.header-container {
  max-width: 1400px;
  /* Prevents unwanted stretching */
  width: 90%;
  /* Ensures a flexible layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: visible !important;
}

/* Logo - ensures proper scaling */
.header-logo img {
  max-height: 130px !important;
  /* Slightly larger */
  vertical-align: middle;
}

.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
  transform: translateX(-50%);
}

/* Navigation menu - keeps items evenly spaced */
.header-nav {
  display: flex;
  gap: 30px;
  /* Keeps consistent spacing */
  align-items: center;
}

/* Navigation links */
.header-option {
  font-weight: 600;
  letter-spacing: 0px;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
  font-weight: normal;
  white-space: nowrap;
  /* Prevents text from wrapping */
  transition: color 0.3s ease;
}

.header-option:hover {
  color: #ddd;
}

/* User icon on the right */
.header-user-icon {
  display: flex;
  align-items: center;
  padding-right: 20px;
  position: relative;
  /* Ensures dropdown is positioned correctly */
  z-index: 1050;
  /* Bootstrap default dropdown z-index */
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .header-container {
    width: 95%;
  }

  .header-nav {
    gap: 30px;
  }

  .header-option {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .header-navbar {
    padding: 8px 0;
  }

  .header-logo img {
    max-height: 40px;
  }

  .header-nav {
    gap: 20px;
  }

  .header-option {
    font-size: 14px;
  }
}