/* Footer Container */
.footer-container {
  background-color: #f2f2f2;
  padding: 50px 0;
  width: 100%;
  margin-top: 50px;
  text-align: center;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  font-family: "Helvetica-Neue", sans-serif !important;
}

/* Footer Content Layout */
.footer-content {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
}

/* Logo and Heading (50%) */
.footer-logo-section {
  text-align: left;
}

.footer-logo {
  width: 300px;
  margin-bottom: 15px;
  display: block;
}

.footer-heading {
  font-weight: bold;
  color: #2f3b68;
  font-size: 27px;
  padding-left: 23px;
  margin-bottom: 10px;
  font-family: "Helvetica-Neue", sans-serif;
}

/* Contact and Links (25% Each) */
.footer-contact,
.footer-links {
  font-family: "Helvetica-Neue-Light", sans-serif !important;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
}

.footer-subheading{
  margin-bottom: 30px !important;
}

/* Links Styling */
.footer-links a,
.footer-contact a {
  text-decoration: none;
  color: #000;
  transition: color 0.3s ease-in-out;
}

.footer-links p,
.footer-contact p {
  margin-bottom: 0;
  font-family: "Helvetica-Neue-Light", sans-serif !important;
}

.footer-links-caption{
  margin-bottom: 40px !important;
}

.footer-contact a {
  margin-bottom: 20px !important;
}

.footer-links a:hover,
.footer-contact a:hover {
  color: #4a5a91;
}

/* Copyright */
.footer-copyright-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  font-size: 14px;
}

.footer-copyright {
  text-align: center;
  font-weight: bold;
  font-family: "Helvetica-Neue-Light", sans-serif !important;
}

.footer-spacer {
  visibility: hidden;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo-section,
  .footer-contact,
  .footer-links {
    text-align: center;
  }

  .footer-copyright {
    text-align: center;
  }
}