.short-description-dashboard::placeholder{
    font-style: italic;
}

.ql-container{
    min-height: 100px;
}

.loader-admin{
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0, 0.4);
}
