.navbar-options-marketplace .nav-link{
    color: #222222 !important;
    padding-left: 60px !important;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
}

.navbar-options-marketplace .nav-link:hover{
    color: #536BC1 !important;
}

.dropdown-toggle::after{
    display:none !important;
}

.basic-nav-dropdown-marketplace:hover{
    color:#536BC1 !important;
}

.overlay-container-marketplace {
    position: relative;
    width: 100%; /* Adjust width for responsiveness */
    padding-left: 9% !important;
    padding-right:15% !important;
    margin: 0 auto; /* Center the overlay horizontally */
    max-height: 104px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0) !important;
    z-index: 2;
    cursor: pointer;
  }