/* Overall Section Styling */
/* Overall Section Styling */
.deals-container {
  background-color: #0f1931;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 1200px; /* Set a max width */
  width: 100%;
}

  
  /* Grid Layout */
  .deals-grid {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  /* Individual Deal Card */
  .deal-card {
    display: flex;
    justify-content: center;
  }
  
  /* Deal Content */
  .deal-content {
    background-color: white;
    padding: 25px;
    border-radius: 12px;
    width: 350px;
    text-align: left;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  /* Deal Header (Title + Bookmark) */
  .deal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
  }
  
  .deal-title {
    font-weight: bold;
    margin-bottom: 10px;
    flex: 1;
    color: #272f53;
    font-size: 23px;
    font-family: "Helvetica-Neue", sans-serif;
  }
  
  .bookmark-icon {
    font-size: 20px;
    color: #272f53;
  }
  
  /* Description */
  .deal-description {
    font-size: 16px;
    color: #272f53;
    margin-bottom: 17px;
    height: 120px;
    font-family: "Helvetica-Neue-Light", sans-serif;
  }
  
  /* Divider */
  .deal-divider {
    border-top: 2px solid #0f1931;
    margin: 0 -25px 15px -25px;
  }
  
  /* Deal Details */
  .deal-details {
    display: grid;
    gap: 15px;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  
  .two-columns {
    grid-template-columns: 1fr 1fr;
  }
  
  .three-columns {
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  /* Detail Labels */
  .detail-label {
    color: #272f53;
    font-family: "Helvetica-Neue-Light", sans-serif;
  }
  
  .detail-value {
    color: #272f53;
    font-family: "Helvetica-Neue", sans-serif;
    font-weight: bold;
    font-size: 17px;
  }
  
  /* Tags */
  .deal-tags {
    margin-top: auto;
  }
  
  .tag {
    display: inline-block;
    border: 1px solid #3e4e8c;
    color: #3e4e8c;
    padding: 6px 12px;
    border-radius: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .deals-grid {
      width: 90%;
    }
  }
  
  @media (max-width: 768px) {
    .deals-container {
      padding: 30px 0;
    }
  
    .deals-grid {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  
    .deal-card {
      width: 90% !important;
    }
  
    .deal-content {
      width: 100%;
    }

    .detail-value{
        font-size: 16px;
        font-family: "Helvetica-Neue-Light", sans-serif;
    }

    .detail-label{
        font-size: 13px;
    }

    .deal-description{
        margin-bottom: 0;
        line-height: 1.3;
    }

    .deal-header{
        height:42px;
    }
  }
  