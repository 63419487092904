.login-page {
    min-height: 100vh;
    display: grid;
    align-items: stretch;
  }
  
  .carousel-inner {
    height: 100%;
  }
  
  .login-heading {
    font-family: "Geologica", sans-serif;
    line-height: 38px;
    font-size: 28px;
    color: #222222;
  }
  
  .pl-4 {
    padding-left: 4%;
  }
  
  .mr-auto {
    margin-right: auto !important;
  }
  
  .login-subheading {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    line-height: 25px;
    color: #222222;
  }

  .login-signup-button {
    margin-left: auto;
    margin-right: auto;
    display: flex !important;
    font-size: 25px !important;
    background: #434a79 0% 0% no-repeat padding-box !important;
    border: 2px solid #ffffff !important;
    border-radius: 15px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    color: white !important;
  }
  
  .login-button-orientation {
    margin-right: auto;
    margin-left: 4%;
    width: 80%;
  }
  
  .login-google-signin {
    min-height: 6vh;
    height: auto;
  }
  
  .login-label {
    font-size: 14px;
    font-family: "Lato", sans-serif;
    font-weight: bold;
  }
  
  /* .form-control {
    border: 1px solid #ced4da;
    transition: border-color 0.2s;
    min-height: 6vh;
    height: auto;
    &:focus {
      box-shadow: 0 0 0 0.1rem #536bc1 !important;
    }
  } */
  
  .form-control:focus + .login-label {
    color: #536bc1;
  }
  
  body {
    color: #222222;
  }
  
  .login-signin {
    background-color: #536bc1 !important;
    width: 100%;
    min-height: 6vh;
    font-family: "Lato", sans-serif !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 25px;
    letter-spacing: 1px;
    height: auto;
    transition: background-color 0.2s;
    &:hover {
      background-color: #4b5faa !important; /* Keep the original background color */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Apply a box-shadow on hover */
    }
  }
  
  .login-carousel-paragraph {
    font-family: "Lato", sans-serif;
    font-size: 20px;
    color: black;
    font-style: italic;
    text-align: left;
    opacity: 0.75;
  }
  
  .login-carousel-name {
    font-family: "Lato", sans-serif;
    margin-top: 3%;
    font-size: 24px;
    font-weight: bold;
    color: black;
    text-align: left;
    line-height: 35px;
    margin-bottom: 0;
  }
  
  .login-carousel-title{
    font-family: "Lato", sans-serif;
    font-size: 16px;
    color: black;
    text-align: left;
    line-height: 25px;
    opacity: 0.5;
  }