#about-us-main-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 2.5rem;
  padding-top: 300px;
  font-weight: bold;
  max-width: 80%;
}

.banner-paragraph {
  color: white;
  font-family: "Helvetica-Neue-Light", sans-serif;
  line-height: 30px;
  opacity: 0.7;
  font-size: 22px;
}

.aboutUsTitle {
  font-family: "Helvetica-Neue", sans-serif;
  color: #161c3b;
  font-weight: bold;
  font-size: 27px;
}

.aboutUsParagraph {
  font-family: "Helvetica-Neue-Light", sans-serif;
  color: #161c3b;
  font-size: 20px;
  line-height: 27px;
}

.subscribe-row {
  background: #161c3b 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #000000d6 !important;
  border-radius: 30px !important;
  padding: 0 !important;
  width: 100%;
}

.subscribe-now-heading {
  color: white;
  font-family: "Helvetica-Neue", sans-serif;
  font-size: 33px;
  font-weight: bold;
}

#subscribe-button {
  background: #3b477e;
  border: 1px solid #575757 !important;
  position: absolute;
  border-radius: 30px !important;
  font-size: 22px;
  font-family: "Helvetica-Neue-Light", sans-serif !important;
  padding: 2% 3% !important;
  ;
  line-height: 20px !important;
  right: 0%;
  bottom: 0%;
  top: 0%;
}

#subscribe-input {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #575757;
  border-radius: 15px;
  margin-right: 2%;
  font-family: "Helvetica-Neue-Light", sans-serif;
}

#about-sections-title {
  font-family: "Helvetica-Neue", sans-serif;
  color: #272F53;
  text-transform: uppercase;
  font-size: 65px;
  font-weight: bold;
  text-align: center;
}

.section-scrolling-category {
  color: #526ABF;
  font-family: "Helvetica-Neue", sans-serif;
  font-size: 45px !important;
  text-align: right;
  padding-top: 25px;
  padding-right: 2%;
}

.section-scrolling-category-separator {
  border-right: 3px solid #272F53 !important;
}

#aboutus-quote {
  font-size: 30px;
  font-family: "Helvetica-Neue", sans-serif;
  color: #161B3B;
  font-weight: bold;
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}


@media (max-width: 576px) {
  .subscribe-now-heading {
    font-size: 18px;
  }

  .mobile-padding-20 {
    padding: 20px;
    width: 100%;
  }

  .mobile-w-100 {
    width: 100%;
  }

  #about-us-main-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 24px;
    padding-top: 300px;
    font-weight: bold;
    max-width: 80%;
    width: 100%
  }

  #aboutus-quote {
    font-size: 20px;
    font-family: "Helvetica-Neue", sans-serif;
    color: #161B3B;
    font-weight: bold;
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }  
}