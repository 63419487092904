.onboardingform-heading {
  font-size: 40px;
  line-height: 40px;
  font-family: "Helvetica-Neue", sans-serif;
  color: #ffffff;
}

.MuiInput-onboardingform {
  text-align: center; /* Center align the text horizontally */
  display: flex !important;
  align-items: center !important; /* Center align the text vertically */
  justify-content: center; /* Center align the text horizontally */
  background-color: #3b477e !important;
  font-family: "Helvetica-Neue-Light", sans-serif;
  font-size: 18px;
  line-height: 30px;
  width: 100%;
}

.onboardingForm-button{
    color: white !important;
    background-color: #3b477e !important;
    font-family: "Helvetica-Neue-Light", sans-serif;
    opacity: 0.7;
    font-size: 18px;
    line-height: 30px;
    width: 100%;
    border: 2px solid #3b477e !important;
    border-bottom: 2px solid white !important;
    border-radius: 0 !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.onboardingForm-button.active-onboardingForm-button{
    opacity:1;
    border: 2px solid #1876d2 !important;
}

.onboarding-form-select{
    border-radius: 0 !important;
    background: white !important;
    color: #272f53 !important;
    font-family: "Helvetica-Neue-Light", sans-serif !important;
    padding:0 !important;
    border-radius: 30px !important;
    width:auto !important;
    /* padding-left: 50px !important;
    padding-right: 50px !important; */
} 

.onboardingform{
    font-size: 25px;
}

#sector{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 20px;
}

.onboardingform-from-to{
    background-color: white !important;
    border-radius: 4px !important;
    text-align: center;
    color:#272f53 !important;
}

.onboardingform-range{
    width:60%;
}

@media (max-width: 768px) {
    .onboardingform-heading{
        font-size: 25px;
        margin-top: 24px !important;
    }

    .onboardingform-label{
        font-size: 20px;
    }

    .onboardingform-heading{
        font-size: 25px !important;
    }

    .onboardingform-range{
        width:100%;
    }
}