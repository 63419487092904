.plan-card-new {
    background: #ffffff;
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    padding-left: 0px ;
    padding-right: 0px;
    /* height: 36rem; */
  }
  
  .plan-card-title-new {
    font-family: "Helvetica-Neue", sans-serif;
    font-size: 35px !important;
    margin-bottom: 0 !important;
  }
  
  .plan-card-subtitle-new {
    font-size: 16px;
    font-family: "Helvetica-Neue-Light", sans-serif;
    color: #222222;
    opacity: 0.9;
  }
  
  .plan-card-tags-new {
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
  }
  
  
  .plan-card-tag-new {
    font-family: 'Geologica' , sans-serif;
    font-size: 180%;
    font-weight: bold;
    margin-bottom: 0 !important;
    /* margin-left: auto !important;
    margin-right: auto !important; */
  }
  
  .plan-card-subtag-new {
    font-family: 'Geologica' , sans-serif;
    font-size: 18px;
    font-weight: 100;
    padding-left: 2%;
  }
  
  .plan-card-button-new {
    width:  100%;
    max-width: 200px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    margin-top: 50px !important;
    font-family: "Helvetica-Neue-Light", sans-serif !important;
    font-size: 24px !important;
    /* border: 2px dashed #000 !important; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-bottom: 50px;
    border-radius: 30px !important;
  }
  
  .plan-card-button-new:hover {
    color: var(--background-color, #fff) !important;
    background-color: var(--color, #3b477e) !important;
  }

  .plan-card-bullet-list-new {
    margin-top: 20px;
    padding: 0;
    border-radius: 0;
    /* Add any other list-specific styles here */
  }
  
  .plan-card-bullet-list-title-new {
    text-align: left;
    font-family: "Helvetica-Neue", sans-serif !important;
    font-size: 18px;
    font-weight: 600;
    padding-top: 2%;
    line-height: 24px;
  }
  
  .plan-card-bullets-new {
    text-align: left;
    list-style-type: disc;
    margin: 0;
    padding: 0; 
    font-family: "Helvetica-Neue-Light", sans-serif !important;
    color:#222222;
    opacity: 0.70;
    font-size: 18px;
    line-height: 24px;
  }
  
  .plan-card-bullets-new li-new {
    margin: 0; /* Remove margin for list items */
    padding: 0; /* Remove padding for list items */
    /* Add any other list item styles here */
  }
  
  .plan-card-bullet-list-new .list-group-item-new{
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-top-width: 1px !important;
    text-align: left;
  }

  .plan-card-bullets-new li:last-child{
    border-bottom: 0;
  }
  .plan-card-list-group-contents-new{
    padding-left: 5%;
    padding-right: 5%;
  }

  @media (max-width: 768px) {
    .plan-card-new{
      padding-left: 36px !important;
      padding-right: 36px !important;
    }
  }